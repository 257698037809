import gql from 'graphql-tag'
import { createDefaultClient } from '../../../vue-apollo'

const defaultClient = createDefaultClient()

const fetchTypes = async ({ commit }) => {
  const res = await defaultClient.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query {
        types {
          rows {
            id
            name
            source
            dimensions {
              name
              alias
              default
            }
            metrics {
              name
              alias
              category
              format
              default
            }
            category
            trafficSourceParams
          }
        }
      }
    `,
  })
  commit('SET_TYPES', res.data.types.rows)
}

export default {
  fetchTypes,
}
