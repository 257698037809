import { confirmed, email, integer, max, min, min_value as minValue, numeric, required } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('required', {
  ...required,
  message: 'This field is required',
})

extend('email', {
  ...email,
  message: 'Email is invalid',
})

extend('requiredColumn', {
  validate: value => value.required,
  message: 'Please select at least one column',
})

extend('requiredSelect', {
  validate: value => value.selected,
  message: 'Select at least one column to display by default',
})

extend('confirmed', {
  ...confirmed,
  message: 'The confirmation field does not match',
})

extend('minPass', {
  ...min,
  message: 'The password must contain at least 6 characters',
})

extend('maxPass', {
  ...max,
  message: 'The password must contain no more than 128 characters',
})

extend('trackingUrl', {
  // eslint-disable-next-line no-useless-escape
  validate: value => /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(app\.appsflyer\.com\/|af\.admon\.pro\/|app\.adjust\.com\/|ad\.admon\.pro\/|redirect\.appmetrica\.yandex\.com\/|trk\.mail\.ru|trk\.admon\.pro|re\.admon\.pro)/.test(
    value,
  ),
  message:
    'This field does not support this type of tracking link',
})

extend('url', {
  validate: value => {
    try {
      // eslint-disable-next-line no-new
      new URL(value)
    } catch {
      return false
    }
    return true
  },
  message:
    'This field must be an url',
})

extend('numeric', numeric)
extend('integer', integer)
extend('minValue', minValue)
