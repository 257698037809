const setActiveResourceAndView = ({ commit }, viewAndResource) => {
  commit('SET_ACTIVE_RESOURCE_AND_VIEW', viewAndResource)
}

const changeActiveResourceAndView = ({ commit }, viewAndResource) => {
  commit('SET_ACTIVE_RESOURCE_AND_VIEW', viewAndResource)
  commit('statistics/RESET_DIMENSIONS_FILTERS', true, { root: true })
}

export default {
  setActiveResourceAndView,
  changeActiveResourceAndView,
}
