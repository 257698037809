import { getQueryParam, setQueryParams } from '../queryParams'

const PAGE_PARAM_NAME = 'page'

export function getPage() {
  return getQueryParam(PAGE_PARAM_NAME)
}

export function setPage(page) {
  setQueryParams({ [PAGE_PARAM_NAME]: page })
}
