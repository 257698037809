/* eslint-disable no-param-reassign */

import { setAllColumnFilters, setQueryParams } from '../../../services/queryParams'
import { removeOrder, setLimit, setOrder, setPage } from '../../../services/statisticsQueryParams'

/**
 * @deprecated Since version 2.2.14.
 */
const SET_STATS = (state, data) => {
  state.stats = data
}

const SET_ACTIVE_EXPRESSIONS = (state, { expressions, dimension }) => {
  state.expressions = { ...state.expressions, [dimension]: expressions }
}

const SET_DATE_FILTER = (state, { dateFilters }) => {
  state.filters.date = dateFilters
  setQueryParams({ dateFilters: dateFilters.join(',') })
}

const SET_DIMENSION_FILTER = (state, { dimension, expressions }) => {
  const newFiltersState = { ...state.filters.dimensions, [dimension]: expressions }
  state.filters.dimensions = newFiltersState
  setAllColumnFilters(newFiltersState)
}

const RESET_DIMENSIONS_FILTERS = state => {
  state.filters.dimensions = []
  setAllColumnFilters({})
}

const SET_ACTIVE_COLUMNS = (state, activeColumns) => {
  if (activeColumns && activeColumns.length) {
    state.activeColumns = activeColumns
    setQueryParams({ columns: activeColumns.join(',') })
  }
}

const SET_PAGE = (state, page) => {
  state.page = page
  setPage(page)
}

const SET_LIMIT = (state, limit) => {
  state.limit = limit
  setLimit(limit)
}

const SET_ORDER = (state, order) => {
  state.order = order
  if (order) {
    setOrder(order)
  } else {
    removeOrder()
  }
}

const SET_FULLSCREEN = state => {
  state.fullscreen = !state.fullscreen
}

export default {
  SET_STATS,
  SET_ACTIVE_EXPRESSIONS,
  SET_DATE_FILTER,
  SET_DIMENSION_FILTER,
  RESET_DIMENSIONS_FILTERS,
  SET_ACTIVE_COLUMNS,
  SET_PAGE,
  SET_LIMIT,
  SET_ORDER,
  SET_FULLSCREEN,
}
