export { UserRole } from '@marvin/core/dist/shared/grpc/generated/users/UserRole'
export { UserStatus } from '@marvin/core/dist/shared/grpc/generated/users/UserStatus'
export { FilterMatchType } from '@marvin/core/dist/shared/grpc/generated/resources/FilterMatchType'
export { AdTemplateType } from '@marvin/core/dist/shared/grpc/generated/ad_templates/AdTemplateType'
export { SpecificConditionType } from '@marvin/core/dist/shared/grpc/generated/resources/SpecificConditionType'
export { SpecificValueType } from '@marvin/core/dist/shared/grpc/generated/resources/SpecificValueType'

export const DecodedUserRole = {
  USER_ADMIN: 'USER_ADMIN',
  USER_USER: 'USER_USER',
}

export const DEFAULT_REMEMBERED_DATA = '{}'
