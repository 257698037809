import { setQueryParams } from '../../../services/queryParams'

/* eslint-disable no-param-reassign */
const SET_ACTIVE_RESOURCE_AND_VIEW = (state, data) => {
  state.selectedResourceAndView = data
  setQueryParams(data)
}

export default {
  SET_ACTIVE_RESOURCE_AND_VIEW,
}
