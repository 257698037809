import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm'
import httpDriver from '@websanova/vue-auth/dist/drivers/http/axios.1.x'
import routerDriver from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x'
import { API_URL } from '../config'
import router from '../router'
import authDriver from './tokenBehaviour'

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = API_URL

Vue.router = router

const vueAxios = Vue.axios

vueAxios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 500) {
      localStorage.removeItem('auth_token_default')
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    }
    return Promise.reject(error)
  },
)

// eslint-disable-next-line import/extensions
Vue.use(auth, {
  plugins: {
    http: vueAxios, // Axios
    // http: Vue.http, // Vue Resource
    router: Vue.router,
  },
  drivers: {
    // eslint-disable-next-line global-require
    auth: authDriver,
    // eslint-disable-next-line
    http: httpDriver,
    // eslint-disable-next-line
    router: routerDriver
  },
  options: {
    authRedirect: { path: '/' },
    forbiddenRedirect: { path: '/' },
    rolesKey: 'role',
    loginData: {
      url: 'auth',
      method: 'POST',
      redirect: '/',
      fetchUser: true,
    },
    parseUserData(data) {
      return data
    },
    fetchData: {
      url: 'auth/current',
      method: 'GET',
      enabled: true,
    },
    refreshData: {
      url: 'auth/refresh',
      method: 'GET',
      enabled: true,
    },
  },
})
