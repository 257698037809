import gql from 'graphql-tag'
import { createDefaultClient } from '../../../vue-apollo'

const defaultClient = createDefaultClient()

const createResource = async (
  _,
  {
    externalId,
    name,
    typeId,
    userId,
    parsingRules,
    appIds,
    clickIntegrationExceptionSources,
    limit,
    installLimit,
    conversionLimit,
    sourceLimit,
    clickIntegration,
    isPublisher,
    restrictions,
    specificParsingRules,
    restrictedPeriods,
    filters,
    fraudCountries,
    permittedCountries,
    monitorEvents,
    trackingSystem,
    apiIntegration,
    apiIntegrationAppId,
    apiIntegrationEvents,
  },
) => {
  const data = {
    externalId,
    name,
    typeId,
    ownerId: userId,
    clickIntegration,
    isPublisher,
    parsingRules,
    appIds,
    clickIntegrationExceptionSources,
    limit,
    installLimit,
    conversionLimit,
    sourceLimit,
    restrictions,
    restrictedPeriods,
    filters,
    fraudCountries,
    permittedCountries,
    monitorEvents,
    trackingSystem,
    apiIntegration,
    apiIntegrationAppId,
    apiIntegrationEvents,
  }

  if (specificParsingRules?.length > 0) {
    data.specificParsingRules = {
      rules: specificParsingRules,
    }
  }

  const res = await defaultClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: gql`
      mutation ($data: CreateResourceInput!) {
        createResource(data: $data) {
          id
          externalId
          name
          created
          updated
        }
      }
    `,
    variables: {
      data,
    },
  })
  return res.data.createResource.name
}

const updateResource = async (
  _,
  {
    id,
    name,
    externalId,
    userId,
    parsingRules,
    appIds,
    clickIntegrationExceptionSources,
    limit,
    installLimit,
    conversionLimit,
    sourceLimit,
    clickIntegration,
    isPublisher,
    restrictions,
    specificParsingRules,
    restrictedPeriods,
    filters,
    fraudCountries,
    permittedCountries,
    monitorEvents,
    trackingSystem,
    apiIntegration,
    apiIntegrationAppId,
    apiIntegrationEvents,
  },
) => {
  const data = {
    name,
    externalId,
    ownerId: userId,
    clickIntegration,
    isPublisher,
    parsingRules,
    appIds,
    clickIntegrationExceptionSources,
    limit,
    installLimit,
    conversionLimit,
    sourceLimit,
    restrictions,
    restrictedPeriods,
    filters,
    fraudCountries,
    permittedCountries,
    monitorEvents,
    trackingSystem,
    apiIntegration,
    apiIntegrationAppId,
    apiIntegrationEvents,
  }

  if (specificParsingRules?.length > 0) {
    data.specificParsingRules = {
      rules: specificParsingRules,
    }
  }

  const res = await defaultClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: gql`
      mutation ($resourceId: ID!, $data: UpdateResourceInput!) {
        updateResource(resourceId: $resourceId, data: $data) {
          name
        }
      }
    `,
    variables: {
      resourceId: id,
      data,
    },
  })
  return res.data.updateResource.name
}

const deleteResource = async (_, { resourceId }) => {
  const res = await defaultClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: gql`
      mutation ($resourceId: ID!) {
        deleteResource(resourceId: $resourceId) {
          id
        }
      }
    `,
    variables: { resourceId },
  })
  return res.data
}

const fetchResources = async ({ commit }) => {
  const res = await defaultClient.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query {
        resources {
          count
          rows {
            id
            externalId
            appIds
            clickIntegrationExceptionSources
            name
            limit
            installLimit
            conversionLimit
            sourceLimit
            clickIntegration
            isPublisher
            fraudCountries
            permittedCountries
            monitorEvents
            trackingSystem
            apiIntegration
            apiIntegrationAppId
            apiIntegrationEvents {
              name
              type
            }
            filters {
              matchType
              filters {
                value
                matchType
                expressions
              }
              groups {
                matchType
                filters {
                  value
                  matchType
                  expressions
                }
                groups {
                  matchType
                  filters {
                    value
                    matchType
                    expressions
                  }
                  groups {
                    matchType
                    filters {
                      value
                      matchType
                      expressions
                    }
                  }
                }
              }
            }
            views {
              id
              name
              dimensions
              metrics
              defaultColumns
              groupFilters {
                matchType
                filters {
                  value
                  matchType
                  expressions
                }
                groups {
                  matchType
                  filters {
                    value
                    matchType
                    expressions
                  }
                  groups {
                    matchType
                    filters {
                      value
                      matchType
                      expressions
                    }
                    groups {
                      matchType
                      filters {
                        value
                        matchType
                        expressions
                      }
                    }
                  }
                }
              }
            }
            type {
              id
              name
              dimensions {
                name
                alias
                default
              }
              metrics {
                name
                alias
                category
                format
                default
              }
            }
            owner {
              id
            }
            parsingRules {
              click {
                source
                tracker
                partner
                banner
                campaign
                clickId
              }
              postback {
                clickId
                source
                tracker
                partner
                banner
                campaign
                clickTimestamp
                installTimestamp
                eventTimestamp
                idfa
                attributedTouchType
                organic
                rejected
                deviceUa
                deviceModel
                deviceOs
                deviceOsVersion
                deviceIp
                deviceType
                appVersion
                appStore
                appId
              }
            }
            specificParsingRules {
              rules {
                condition {
                  value
                  type
                }
                statements {
                  dimension
                  type
                  value
                }
              }
            }
            restrictedPeriods {
              start
              end
            }
            restrictions {
              media {
                videoUpload
              }
              mobile {
                linkGenerator
                revise
              }
            }
            created
            updated
          }
        }
      }
    `,
  })

  commit('SET_RESOURCES', res.data.resources.rows)
}

export default {
  createResource,
  fetchResources,
  updateResource,
  deleteResource,
}
