import gql from 'graphql-tag'
import { createDefaultClient } from '../../../vue-apollo'

const defaultClient = createDefaultClient()

const fetchViewers = async ({ commit }, { resourceId, viewId }) => {
  const res = await defaultClient.query({
    fetchPolicy: 'no-cache',
    query: gql`query ViewersOfView($resourceId: ID!, $viewId: ID!) {
      view(resourceId: $resourceId, viewId: $viewId) {
        viewers {
          id
          email
          created
        }
      }
    }`,
    variables: {
      resourceId,
      viewId,
    },
  })
  commit('SET_VIEWERS', res.data.view.viewers)
}

const deleteViewer = async (_, {
  resourceId, viewId, viewerId,
}) => {
  const res = await defaultClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: gql`mutation ($resourceId: ID!, $viewId: ID!, $viewerId: ID!) {
      deleteViewer(resourceId: $resourceId, viewId: $viewId, viewerId: $viewerId) {
          id
        }
      }`,
    variables: {
      resourceId,
      viewId,
      viewerId,
    },
  })
  return res.data.deleteViewer
}

const createViewer = async (_, {
  resourceId, viewId, email,
}) => {
  const res = await defaultClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: gql`mutation ($email: String!, $resourceId: ID!, $viewId: ID!) {
      createViewer(email: $email, resourceId: $resourceId, viewId: $viewId) {
          id
          email
          credentials {
            email
            password
          }
        }
      }`,
    variables: {
      resourceId,
      viewId,
      email,
    },
  })
  return res.data.createViewer
}

export default {
  fetchViewers,
  createViewer,
  deleteViewer,
}
