import gql from 'graphql-tag'

import { createDefaultClient } from '../../../vue-apollo'

const defaultClient = createDefaultClient()

async function fetchServerTime({ commit }) {
  const response = await defaultClient.query({
    fetchPolicy: 'no-cache',
    query: gql`
        query {
            serverTime {
                time
                timezone
            }
        }
    `,
  })
  commit('SET_SERVER_TIME', response.data.serverTime)
}

export default {
  fetchServerTime,
}
