import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { ApolloLink } from 'apollo-link'
// eslint-disable-next-line import/extensions
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { API_URL } from './config'

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'auth_token_default'

const cache = new InMemoryCache()

function getAuthToken() {
  return localStorage?.getItem(AUTH_TOKEN)
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getAuthToken()
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })
  return forward(operation)
})

export function createDefaultClient() {
  const terminatingLink = createUploadLink({
    uri: `${API_URL}/graphql`,
    headers: { 'Apollo-Require-Preflight': 'true' },
  })
  return new ApolloClient({
    link: authMiddleware.concat(terminatingLink),
    cache,
  })
}

// Call this in the Vue app file
export function createProvider() {
  // Create vue apollo provider
  return new VueApollo({
    defaultClient: createDefaultClient(),
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message,
      )
    },
  })
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
