import actions from './actions'
import mutations from './mutations'

const state = {
  viewers: [],
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
