/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/**
 * @deprecated Since version 2.2.14. Use 'getFilterExpressions' service instead.
 */
const findExpressions = state => dimension => state.expressions[dimension]

const PARTIAL_MATCH_PREFIX = '%'

function isExpressionPartialMatch(expression) {
  return expression.startsWith(PARTIAL_MATCH_PREFIX)
}

function sanitizePartialMatchExpression(expression) {
  return expression.slice(PARTIAL_MATCH_PREFIX.length)
}

function getDimensionGroupFilter(dimension, expressions) {
  const strictExpressions = expressions.filter(expression => !isExpressionPartialMatch(expression))
  const partialMatchExpressions = expressions
    .filter(isExpressionPartialMatch)
    .map(sanitizePartialMatchExpression)
  const filters = []
  if (strictExpressions.length) {
    filters.push({
      matchType: 'FILTER_MATCH_IN',
      value: dimension,
      expressions: strictExpressions,
    })
  }
  if (partialMatchExpressions.length) {
    filters.push({
      matchType: 'FILTER_MATCH_LIKE',
      value: dimension,
      expressions: partialMatchExpressions,
    })
  }
  return {
    matchType: 'FILTER_MATCH_OR',
    filters,
    groups: [],
  }
}

function getGroupFilters(state) {
  const { date, dimensions } = state.filters
  return [
    {
      matchType: 'FILTER_MATCH_AND',
      filters: [
        { value: 'date', matchType: 'FILTER_MATCH_GREATER_EQUAL', expressions: [date[0]] },
        { value: 'date', matchType: 'FILTER_MATCH_LESS_EQUAL', expressions: [date[1] || date[0]] },
      ],
      groups: Object.entries(dimensions)
        .map(([dimension, expressions]) => getDimensionGroupFilter(dimension, expressions)),
    },
  ]
}

const getActiveColumns = state => {
  return state.activeColumns
}

const getFullscreen = state => {
  return state.fullscreen
}

export default {
  findExpressions,
  getActiveColumns,
  getFullscreen,
  getGroupFilters,
}
