/* eslint-disable import/extensions */
import moment from 'moment'
import { getAllColumnFilters, getQueryParam } from '../../../services/queryParams.js'
import { getLimit, getOrder, getPage } from '../../../services/statisticsQueryParams'
import actions from './actions.js'
import getters from './getters.js'
import mutations from './mutations.js'

const dateFilters = getQueryParam('dateFilters')
const columns = getQueryParam('columns')
const columnFilters = getAllColumnFilters()

const state = {
  filters: {
    date: dateFilters
      ? dateFilters.split(',')
      : [moment().subtract(6, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    dimensions: columnFilters,
  },
  activeColumns: columns ? columns.split(',') : [],
  page: getPage(),
  limit: getLimit(),
  order: getOrder(),
  fullscreen: false,
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
