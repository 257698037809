export default {
  request(req, token) {
    // eslint-disable-next-line no-underscore-dangle
    this.drivers.http.setHeaders.call(this, req, { Authorization: `Bearer ${token}` })
  },
  response(res) {
    // eslint-disable-next-line no-bitwise
    return ~res.request.responseURL.indexOf('auth') ? res.data.token : null
  }
}
